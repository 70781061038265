import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Tariffs = ({ prevArrow, tariffNextArrow }) => {
  const [isActive, setIsActive] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsActive(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    prevArrow: prevArrow,
    nextArrow: tariffNextArrow,
    infinite: true,
    speed: 500,
    slidesToShow: isActive ? 1 : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          centerPadding: '20px',
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: "unslick"
      },
    ],
  };

  return (
    <div className= "laptop:flex laptop:flex-col tablet:ml-[10px] laptop:ml-[70px] laptop:mt-20 desktop:flex desktop:flex-col desktop:items-center desktop:justify-center">
      <div className="h-[380px] laptop:w-[1250px] mobile:w-[330px] desktop:w-[1890px] laptop:-ml-[60px] rounded-t-full rounded-b-full rounded-l-full bg-turquoiseShadow opacity-20 blur-2xl rotate-6 z-1 -mt-[250px] tablet:w-[707px] "></div>

      <div className="bg-bg-darkPurple desktop:max-w-[1568px] laptop:max-w-[1110px] mobile:ml-3 tablet:-ml-[5px] mobile:-mt-28">
        <div className="laptop:flex tablet:ml-[40px] ">
          <h1 className="text-white text-[40px] mr-auto font-light mobile:ml-[20px] tablet:mb-7 desktop:-ml-14">Тарифы</h1>
          <button className="cursor-pointer text-white text-xl opacity-60 hover:opacity-100 mobile:ml-5 mobile:text-[24px] tablet:absolute tablet:ml-[220px] tablet:mt-[880px] mobile:absolute mobile:mt-[2790px] mobile:whitespace-nowrap mobile:text-center laptop:relative laptop:mt-0 desktop:relative desktop:mt-0 mr-[0px] mb-[0px] ml-[250px] z-20">Сравнить тарифы &gt;&gt;</button>
        </div>

        {isActive ? (
          <div className="tariffs-slider tablet:relative tablet:z-20 tablet:-ml-5 font-light text-xl mobile:-m-[30px]  mobile:-ml-[75px] desktop:ml-96">
            <Slider {...settings}>
              <div className="slider-container-1 mobile:ml-24 w-[320px] bg-gradient-to-b from-cardGradient to-bg-darkPurple rounded-xl mt-10 mr-10 mb-10 border-cardBorder border flex flex-col items-center justify-center p-[30px] relative z-30">
                <h1 className="text-4xl text-center font-normal text-white mt-5">
                  Начальный
                </h1>
                <div className="pt-[30px] flex flex-col gap-6 items-center relative z-10 mb-10 mt-7">
                  <hr className="w-[260px] mobile:w-[280px] h-[1px] text-white" />
                  <span className="text-white">Конструктор презентаций</span>
                  <hr className="w-[260px] mobile:w-[280px] h-[1px] text-white" />
                </div>
                <ul className="text-white pt-[30px] flex flex-col gap-4 list-disc pl-[30px] justify-self-center ">
                  <li>
                    <span>экспорт презентаций в PDF, PNG</span>
                  </li>
                  <li>
                    <span>экспорт результатов опросов в Exel, PDF</span>
                  </li>
                  <li>
                    <span>до 3 презентаций на аккаунте</span>
                  </li>
                  <li>
                    <span>до 5 слайдов в одной презентации</span>
                  </li>
                  <li>
                    <span>1 интерактивный слайд</span>
                  </li>
                  <li>
                    <span>1 код опроса на сайт</span>
                  </li>
                </ul>
                <div className="flex flex-col items-center mt-4">
                  <span className="text-2xl text-white pt-14 pb-8">
                    0 ₽ / месяц
                  </span>
                  <button className="w-[270px] h-[50px] rounded-[7px] bg-cardBtnPurple cursor-pointer">
                    <span className="text-white text-[18px]">Выбрать</span>
                  </button>
                </div>
              </div>

              <div className="w-[320px] slider-container-1 max-md:w-[500px] bg-gradient-to-b from-cardGradient to-bg-darkPurple rounded-xl mt-10 mr-10 mb-10 border-cardBorder border flex flex-col items-center p-[30px] relative z-10">
                <h1 className="text-4xl text-white mt-5 text-center font-normal">
                  Базовый
                </h1>
                <div className="pt-[30px] flex flex-col gap-6 items-center mb-6 mt-5">
                  <hr className="w-[360px] mobile:w-[280px] h-[1px] text-white" />
                  <span className="text-white pl-7 max-w-[360px] text-center">
                    Все возможности тарифа "Начальный", а также
                  </span>
                  <hr className="w-[360px] mobile:w-[280px] h-[1px] text-white" />
                </div>
                <ul className="text-white pt-[30px] flex flex-col gap-4 list-disc pl-[30px]">
                  <li>
                    <span>аналитика от НАФИ</span>
                  </li>
                  <li>
                    <span>брендирование</span>
                  </li>
                  <li>
                    <span>до 5 презентаций на аккаунте</span>
                  </li>
                  <li>
                    <span>до 10 слайдов в одной презентации</span>
                  </li>
                  <li>
                    <span>до 5 интерактивных слайдов в презентации</span>
                  </li>
                  <li>
                    <span>до 3 кодов опроса на сайт</span>
                  </li>
                </ul>
                <div className="flex flex-col items-center mt-4">
                  <span className="text-2xl text-white pt-14 pb-8">
                    550 ₽ / месяц
                  </span>
                  <button className="w-[270px] h-[50px] rounded-[7px] bg-cardBtnPurple cursor-pointer">
                    <span className="text-white text-[18px]">Выбрать</span>
                  </button>
                </div>
              </div>
              

              <div className="w-[320px] slider-container-1 max-md:w-[500px] bg-gradient-to-b from-cardGradient to-bg-darkPurple rounded-xl mt-10 mr-10 mb-10 border-cardBorder border flex flex-col items-center p-[30px] relative z-10">
                <h1 className="text-4xl text-white mt-5 text-center font-normal">
                  Премиум
                </h1>
                <div className="pt-[30px] flex flex-col gap-6 items-center mb-5 mt-6">
                  <hr className="w-[360px] mobile:w-[280px] h-[1px] text-white" />
                  <span className="text-white pl-7 max-w-[360px] text-center">
                    Все возможности тарифа "Базовый", а также
                  </span>
                  <hr className="w-[360px] mobile:w-[280px]  h-[1px] text-white" />
                </div>
                <ul className="text-white pt-[30px] flex flex-col gap-5 list-disc pl-[30px]">
                  <li>
                    <span>функция "Мульти-опрос"</span>
                  </li>
                  <li>
                    <span>оплата от юр.лица</span>
                  </li>
                  <li>
                    <span>до 20 презентаций на аккаунте</span>
                  </li>
                  <li>
                    <span>до 50 слайдов в одной презентации</span>
                  </li>
                  <li>
                    <span>до 15 интерактивных слайдов в презентации</span>
                  </li>
                  <li>
                    <span>до 10 кодов опроса на сайт</span>
                  </li>
                </ul>
                <div className="flex flex-col items-center">
                  <span className="text-2xl text-white pt-14 pb-8">
                    1120 ₽ / год
                  </span>
                  <button className="w-[270px] h-[50px] rounded-[7px] bg-cardBtnPurple cursor-pointer">
                    <span className="text-white text-[18px]">Выбрать</span>
                  </button>
                </div>
              </div>
            </Slider>
          </div>
        ) : (
          <div className="flex justify-center desktop:gap-16  laptop:ml-[60px]   ">
            <div className="slider-container-1 w-[320px] bg-gradient-to-b from-cardGradient to-bg-darkPurple rounded-xl mt-10 mr-10 mb-10 border-cardBorder border flex flex-col items-center p-[30px] relative z-20">
              <h1 className="text-[30px] text-white">Начальный</h1>
              <div className="pt-[30px] flex flex-col gap-6 items-center relative z-10">
                <hr className="w-[260px] h-[1px] text-white" />
                <span className="text-white">Конструктор презентаций</span>
                <hr className="w-[260px] h-[1px] text-white" />
              </div>
              <ul className="text-white pt-[30px] flex flex-col gap-2 list-disc pl-[30px]">
                <li>
                  <span>экспорт презентаций в PDF, PNG</span>
                </li>
                <li>
                  <span>экспорт результатов опросов в Exel, PDF</span>
                </li>
                <li>
                  <span>до 3 презентаций на аккаунте</span>
                </li>
                <li>
                  <span>до 5 слайдов в одной презентации</span>
                </li>
                <li>
                  <span>1 интерактивный слайд</span>
                </li>
                <li>
                  <span>1 код опроса на сайт</span>
                </li>
              </ul>
              <span className="text-2xl text-white pt-14 pb-8">
                0 ₽ / месяц
              </span>
              <button className="w-[270px] h-[50px] rounded-[7px] bg-cardBtnPurple cursor-pointer">
                <span className="text-white text-[18px]">Выбрать</span>
              </button>
            </div>

            <div className="w-[320px] slider-container-1 max-md:w-[500px] bg-gradient-to-b from-cardGradient to-bg-darkPurple rounded-xl mt-10 mr-10 mb-10 border-cardBorder border flex flex-col items-center p-[30px] relative z-20">
              <h1 className="text-[30px] text-white">Базовый</h1>
              <div className="pt-[30px] flex flex-col gap-6 items-center">
                <hr className="w-[260px] h-[1px] text-white" />
                <span className="text-white pl-7">
                  Все возможности тарифа "Начальный", а также
                </span>
                <hr className="w-[260px] h-[1px] text-white" />
              </div>
              <ul className="text-white pt-[30px] flex flex-col gap-2 list-disc pl-[30px]">
                <li>
                  <span>аналитика от НАФИ</span>
                </li>
                <li>
                  <span>брендирование</span>
                </li>
                <li>
                  <span>до 5 презентаций на аккаунте</span>
                </li>
                <li>
                  <span>до 10 слайдов в одной презентации</span>
                </li>
                <li>
                  <span>до 5 интерактивных слайдов в презентации</span>
                </li>
                <li>
                  <span>до 3 кодов опроса на сайт</span>
                </li>
              </ul>
              <span className="text-2xl text-white pt-14 pb-8">
                550 ₽ / месяц
              </span>
              <button className="w-[270px] h-[50px] rounded-[7px] bg-cardBtnPurple cursor-pointer">
                <span className="text-white text-[18px]">Выбрать</span>
              </button>
            </div>

            <div className="w-[320px] slider-container-1 max-md:w-[500px] bg-gradient-to-b from-cardGradient to-bg-darkPurple rounded-xl mt-10 mr-10 mb-10 border-cardBorder border flex flex-col items-center p-[30px] relative z-20">
              <h1 className="text-[30px] text-white">Премиум</h1>
              <div className="pt-[30px] flex flex-col gap-6 items-center">
                <hr className="w-[260px] h-[1px] text-white" />
                <span className="text-white pl-7">
                  Все возможности тарифа "Базовый", а также
                </span>
                <hr className="w-[260px] h-[1px] text-white" />
              </div>
              <ul className="text-white pt-[30px] flex flex-col gap-2 list-disc pl-[30px]">
                <li>
                  <span>функция "Мульти-опрос"</span>
                </li>
                <li>
                  <span>оплата от юр.лица</span>
                </li>
                <li>
                  <span>до 20 презентаций на аккаунте</span>
                </li>
                <li>
                  <span>до 50 слайдов в одной презентации</span>
                </li>
                <li>
                  <span>до 15 интерактивных слайдов в презентации</span>
                </li>
                <li>
                  <span>до 10 кодов опроса на сайт</span>
                </li>
              </ul>
              <span className="text-2xl text-white pt-8 pb-8">
                1120 ₽ / год
              </span>
              <button className="w-[270px] h-[50px] rounded-[7px] bg-cardBtnPurple cursor-pointer">
                <span className="text-white text-[18px]">Выбрать</span>
              </button>
            </div>
          </div>
        )}
        <span className="text-[16px] text-white relative z-20 desktop:p-12 desktop:block desktop:text-lg desktop:-mt-[60px] laptop:ml-10 tablet:hidden mobile:text-[10px] mobile:ml-7 mobile:absolute">*Оплата безналичным переводом для юридических лиц и ИП</span>
        <div className="desktop:w-[1154px] tablet:w-[600px] desktop:h-[800px] tablet:h-[900px] absolute bg-gradient-to-t from-purpleShadow to-bg-darkPurple opacity-30 blur-2xl rounded-full z-0 -mt-[500px] -ml-[300px] tablet:z-10 tablet:ml-[40px]"></div>
      </div>
    </div>
  );
};

export default Tariffs;
