import React, { useEffect } from "react";

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div className="relative mobile:hidden tablet:block laptop:block tablet:absolute laptop:absolute">
      <button
        className={"custom-next"}
        style={{
          ...style,
          marginTop: "-350px",
          fontSize: "46px",
          color: "white",
          zIndex: '20'
        }}
        onClick={onClick}
      >
        <span>&gt;&gt;</span>
      </button>

    </div>
  );
};

export default NextArrow;
