import React, { useState } from 'react'


const ReviewsCard = ({ comment }) => {
    const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div>
        <div className=" mobile:w-[320px] tablet:w-[390px] tablet:-ml-[68px] laptop:ml-0 laptop:w-[400px] desktop:w-[400px] desktop:mr-0 laptop:mr-0 mr-40 border-cardBorder border bg-gradient-to-t from-cardGradient to-bg-darkPurple rounded-tl-xl rounded-tr-xl rounded-br-xl desktop:pt-[30px] desktop:pr-[40px] desktop:pb-[20px] desktop:pl-[30px] laptop:pt-[30px] laptop:pr-[40px] laptop:pb-[20px] laptop:pl-[30px] tablet:pt-[30px] tablet:pr-[40px] tablet:pb-[20px] tablet:pl-[30px]  mobile:pr-[25px] mobile:pb-[25px] mobile:pl-[10px] relative z-20">
    <p className="mt-5 mb-5 text-white font-normal">
      { isExpanded? comment.comment : `${comment.comment.slice(0, 100)}...`}
    </p>
    <button className="mb-[20px]" onClick={() => setIsExpanded((prev) => !prev)}>
      <span className="text-white underline text-[14px] font-normal">
      {isExpanded ? "Скрыть комментарий" : "Посмотреть весь комментарий"}
      </span>
    </button>
    <div className="flex items-center gap-4 mt-auto">
      <img
        src={comment.img}
        alt="profile pic"
        className="w-[50px] h-[50px]"
      />
      <span className="text-white text-[14px] font-normal">
        {comment.name}
      </span>
    </div>
  </div></div>
  )
}

export default ReviewsCard