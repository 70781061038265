import ksenyaImg from '../../assets/reviews/ksenya.svg';
import timothyImg from '../../assets/reviews/timothy.svg';
import alexanderImg from '../../assets/reviews/alexander.svg';
import elenaImg from '../../assets/reviews/elena.svg';

export default [
    {
        'id': '1',
        'comment': 'Удобно что есть большая библиотека шаблонов. Сервис быстрый и интуитивно понятный. Еще один плюс, что есть возможность выбрать подходящий тарифный план. Удобно что есть большая библиотека шаблонов. Сервис быстрый и интуитивно понятный. Еще один плюс, что есть возможность выбрать подходящий тарифный план.',
        'name': 'Ксения',
        'img': ksenyaImg
    },
    {
        'id': '2',
        'comment': 'Много лет заказывали статистику у НАФИ, а потом заказывали дизайнеру брендированное оформление. Теперь вся наша аналитика поступает в ЛК Илиметра и мы делаем презентацию прямо здесь. Много лет заказывали статистику у НАФИ, а потом заказывали дизайнеру брендированное оформление. Теперь вся наша аналитика поступает в ЛК Илиметра и мы делаю презентацию прямо здесь',
        'name': 'Тимофей',
        'img': timothyImg
    },
    {
        'id': '3',
        'comment': 'Самое сложное для меня - работа с публикой, меня пугает отсутствие отклика от зала Интерактивы всегда казались чем-то детским, не серьезным. Оказалось, что я просто не умел... Самое сложное для меня - работа с публикой, меня пугает отсутствие отклика от зала Интерактивы всегда казались чем-то детским, не серьезным. Оказалось, что я просто не умел... Самое сложное для меня - работа с публикой, меня пугает отсутствие отклика от зала Интерактивы всегда казались чем-то детским, не серьезным. Оказалось, что я просто не умел...',
        'name': 'Александр',
        'img': alexanderImg
    },
    {
        'id': '4',
        'comment': 'Я заметила значительный прогресс: стало легче держать внимание аудитории, улучшилась дикция и уверенность на выступлении. Рекомедую всем, кто хочет улучшить свои навыки ораторского искусства. Самое сложное для меня - работа с публикой, меня пугает отсутствие отклика от зала Интерактивы всегда казались чем-то детским, не серьезным. Оказалось, что я просто не умел...',
        'name': 'Елена',
        'img': elenaImg
    }


     




]